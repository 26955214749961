import How from "./How";
import Join from "./Join";
import Main from "./Main";
import { Grid, TextField, Typography, useMediaQuery, Button as MuiButton, styled, Button } from "@mui/material";
import { useState, useContext } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../util/firebase";
import toast, { Toaster } from "react-hot-toast";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import UserContext from "../contexts//UserContext";
import { logout } from "../util/helpers";
import HeroGirl from "../assets/hero-girl.png"
import HeroChart from "../assets/hero-chart.png"
import CloverGirl from "../assets/clover-girl.png";
import CloverBoy from "../assets/clover-boy.png";
import WaveLine from "../assets/wave_icon.png";
import CurlyRound from "../assets/curly_round_icon.png"
import TextUnderline from "../assets/text_grd_underline.png"
import FaceBookImg from "../assets/facebook-vector.png"
import InstagarmImg from "../assets/instagram-vector.png"
import YoutubeImg from "../assets/youtube-vector.png"
import LocationIcon from "../assets/location-06.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from "react-multi-carousel";
import CollaborationImage from "../assets/collaboration_image.png"
import RealTimeImage from "../assets/realtime_image.png"
import SocialInsightImage from "../assets/socialinsights_image.png"
import SocialListeningImage1 from "../assets/socialListening1.PNG"
import SocialListeningImage2 from "../assets/socialListening2.PNG"
import FindCreatorsImage1 from "../assets/findCreators.PNG"
import ManageCampaignImage1 from "../assets/manageCampaigns.PNG"
import TrackResultsImage1 from "../assets/trackResults.PNG"
import GiglAgencyImage1 from "../assets/giglAgency.PNG"
import FlashIcon from "../assets/flash.png"
import EllipseTop from "../assets/Ellipse-Top.png"
import EllipseBottom from "../assets/Ellipse-Bottom.png"
import CommunityImage from "../assets/CommunityImage.png"
import CommunityMobile from "../assets/Community-mobile.png"
import BurgerMenuImage from "../assets/menu-02.png"
import BrandCarousel from "./BrandCarousle";
import LandingFooter from "./LandingFooter";
import MobileDrawer from "./MobileDrawer";
import Slider from 'react-infinite-logo-slider'



const creatorsInfo = [
  { name: "Amara", location: "Tokyo, Japan" },
  { name: "Samip", location: "Bengaluru, India" },
  { name: "Mark", location: "London, UK" },
  { name: "Katherine", location: "Paris, France" },
  { name: "Sophia", location: "Bragille, South America" },
  { name: "Carlos", location: "Monterrey, Mexico" },
]



const CustomTextButton = styled(MuiButton)(({ theme }) => ({
  color: "black",
  fontSize: "14px",
  fontWeight: "500",
  '&.bold-text':{
    fontWeight:"600"
  },
  '&.half-width':{
    width:"50%",
  },
  backgroundColor: "white",
  borderColor: "white",
  boxShadow: "0px 1px 2px 0px #1018280D",
  padding: "8px 16px",
  textTransform: "none !important",
  '&:hover': {
    backgroundColor: '#D3D3D3',


  }
}));

const responsive = {
  0: {
    items: 1.2,
  },
  380: {
    items: 1.2,
  },
  512: {
    items: 2,
  },
  665: {
    items: 3,
  },
  767: {
    items: 3,
  },
  870: {
    items: 4,
  },
  1158: {
    items: 4
  },

  1024: {
    items: 4.5,
  },
  1265: {
    items: 6,
  }
};

const brandsList = ["Meyer","Jow", "CanadianWomenFoundation","BellaAura", "DestinationToronto","Unknow","whipdd", "Hauslane", "citizen", "Cuisivin","NordicSunn", "Hymnologie","LG", "Remento" ] 




const DEFAULT_MODAL_TEXT =
  "To subscribe to our waiting list and get first access to our launch in June, please fill out the below details!";
export default function Home() {
  const { authed, user } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [modalText, setModalText] = useState(DEFAULT_MODAL_TEXT);
  const isMobile = useMediaQuery("(max-width:800px)");
  const isSmallMobile = useMediaQuery("(max-width:453px)");
  const [isMobileDrawerOpen,setIsMobileDrawerOpen] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  }

  const navigate = useNavigate();

  const items = creatorsInfo.map((data,i) => <CarousalCard key={i} data={data} />)

  const colorCardData = [
    {
      title: "Unlock the Power of Social Listening & Insights",
      buletPoint: [ {
        title: "Identify Emerging Social Trends",
        subtitle: "Stay on top of social trends and shifting audience preferences to create timely, relevant content and campaigns."
      }, {
        title: "Create Data-Driven Social Strategies",
        subtitle: "Use insights from social listening to adjust your influencer strategy and maximize engagement."
      },{
        title: "Track Cross-Channel and Competitive Landscape Performance",
        subtitle: "Monitor real-time insights about your brand and competitors across social platforms."
      }],
      color: "pink",
      image: SocialInsightImage,
    },
    {
      title: "Track Real-Time Analytics and Performance, Powered by AI",
      buletPoint: [{
        title: "Predictive Insights to Optimize Campaign Performance",
        subtitle: "Use Gigl's AI insights to forecast creator performance and identify the best campaign partners that drive powerful results."
      }, {
        title: "Real-Time Analytics Across Platforms",
        subtitle: "Monitor engagement, conversions, and ROI in real-time across all platforms."
      }, {
        title: "AI-Driven Optimization",
        subtitle: "AI analyzes your goals and marketing objectives to suggest improvements that boost performance and ROI."
      }],
      color: "purple",
      image: RealTimeImage,
      imageSide:"left"
    },
    {
      title: "Seamlessly Manage Every Collaboration",
      buletPoint: [
        {
          title: "Centralized Campaign Dashboard",
          subtitle: "View all influencer collaborations, track progress, and stay on top of deadlines in one easy-to-navigate interface."
        },
        {
          title: "Performance Analytics",
          subtitle: "Access detailed performance metrics such as engagement, reach, and impressions to measure campaign success."
        },
        {
          title: "Seamless Communication",
          subtitle: "Connect, communicate, and manage all campaign deliverables with creators in one streamlined marketing solution."
        },
        {
          title: "Multi-Campaign Functionality",
          subtitle: "Launch and manage various creator campaigns, from paid campaigns, affiliate marketing, gifting, creator events, and more."
        }
      ],
      color: "blue",
      image: CollaborationImage
    },

  ]

  const socialListeningcolorCardData = [

    {
      title: "Track monthly social trends & take action",
      subtitle: "Social Media Trends Monitoring",
      buletPoint: [ {
        title: "Stay on top of social trends across channels",
        subtitle: "Never miss a trend with Gigl's social listening tools. Stay on top of social trends and shifting audience preferences to create timely, relevant content and campaigns that win."
      },{
        title: "Understand how to to take action on trends",
        subtitle: "Understand what's trending and how to take action to recreate trending content for your own brand and social content pipeline."
      }
    ],
      color: "white",
      image: SocialListeningImage1,
    },
    {
      title: "Create winning content pipelines backed by insights",
      buletPoint: [{
        title: "Track top channels driving ROI",
        subtitle: "Keep an eye on what matters and stand out on the right channels that drive ROI."
      }, {
        title: "Monitor trending audios and hashtags that are boosting engagement",
        subtitle: "Track and monitor hashtags and audios that are trending across channels. Plan winning campaigns accordingly."
      }, {
        title: "Track trending content formats",
        subtitle: "Stay up to date on what content formats are driving the most engagement every month. "
      }],
      color: "white",
      image: SocialListeningImage2,
      imageSide:"left"
    },
    {
      title: "Find, invite, & collaborate with the right creators for your brand",
      buletPoint: [{
        title: "Find and invite the best creators for your campaigns",
        subtitle: "Easily search to find the best creators for your campaign goals among thousands."
      }, {
        title: "Track insights that matter",
        subtitle: "Track creator insights that matter for your campaign on Gigl."
      }, {
        title: "Collaborate on viral campaigns",
        subtitle: "Team up with amazing creators in one click through the Gigl platform. "
      }],
      color: "white",
      image: FindCreatorsImage1,
    },    
    {
      title: "Manage & automate your influencer campaigns with ease",
      buletPoint: [{
        title: "Streamline your end-to-end campaign journey on the Gigl Platform",
        subtitle: "Influencer management is a breeze on Gigl with automation and AI-powered toolsets to help you succeed."
      }, {
        title: "Automate your campaign outreach",
        subtitle: "Invite any creator on or off the Gigl Platform to join your campaign. Automate outreach and connect with thousands of influencers."
      }, {
        title: "Manage influencers and workstreams in one place",
        subtitle: "Connect, communicate, and manage all campaign deliverables with creators in one streamlined influencer marketing solution. "
      }],
      color: "white",
      image: ManageCampaignImage1,
      imageSide:"left"
    },
    {
      title: "Track your influencer marketing results in one place",
      buletPoint: [{
        title: "Track creator campaign performance",
        subtitle: "Track live campaign performance results from creators in your campaign dashboard."
      }, {
        title: "Track results across channels",
        subtitle: "Monitor cross channel results to find the best channels for growth."
      }, {
        title: "Iterate based on learnings and results",
        subtitle: "Have all your influencer marketing results in one place and share them with your team to iterate based on learnings. "
      }],
      color: "white",
      image: TrackResultsImage1,
    },  
    {
      title: "Don't have a team? Work with the expert Gigl team",
      buletPoint: [{
        title: "End-to-end influencer marketing agency",
        subtitle: "Our team can plan and manage your entire influencer marketing program from start to finish. Work with our expert agency team to create and launch winning campaigns."
      }, {
        title: "Set your goals, our team will manage the rest",
        subtitle: "Our team has experts to help you drive campaign success. Reach out to our team under the Gigl Enterprise Plan to kickstart your next project."
      },],
      color: "blue",
      image: GiglAgencyImage1,
      imageSide:"left"
    },


  ]


  const handleOpenWaitlist = (e, text = DEFAULT_MODAL_TEXT) => {
    setOpen(true);
    setModalText(text);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggalMobileDrawer = () => {
    setIsMobileDrawerOpen(!isMobileDrawerOpen);
  }

  const handleSubscribe = async () => {
    try {
      await addDoc(collection(db, "waitinglist"), {
        email: email,
        type: type,
        submittedAt: new Date(),
      });
      handleClose();
      toast.success("We'll be in touch soon!");
    } catch (err) {
      toast.error("Something went wrong, please try again later");
    }
  };
  return (
    <div className="landing-container">
      <div className="landing-home">
        <div className="landing-topbar">
          <div
            className="company-title"
            onClick={() => (window.location.href = "/")}
          >
            GIGL
          </div>
          <div className="landing-topbar-menu">
            <div className="menu-button" onClick={() => {
              window.location.href = "/pricing";
            }}>Pricing</div>
            <div className="menu-button" onClick={() => {
              window.location.href = "/blog";
            }}>Blog & Insights</div>
            <div className="menu-button" onClick={() => {
              window.location.href = (user.type === "creator") ? "/creators" : "/dashboard";
            }}>My Dashboard</div>
            <div className="menu-button" onClick={() => {
              window.location.href = "/creatorSignUp";
            }}>Become a Creator</div>


          </div>
          <div className="landing-topbar-menu">
            <div className="menu-button" onClick={
              authed
                ?
                () => { logout() }
                :
                () => { window.location.href = "/login" }
            }>{authed ? "Logout" : "Login"}</div>
            <CustomTextButton onClick={() => {
              window.location.href = "/register";
            }}>Get Started</CustomTextButton>

          </div>
          <div className="burger-menu" onClick={()=>toggalMobileDrawer()}><img src={BurgerMenuImage} alt="burger-menu"/></div>
          <MobileDrawer isOpen={isMobileDrawerOpen} toggleDrawer={toggalMobileDrawer} />
        </div>
        <div className="landing-home-container">
          <div className="home-left">
            <div className="home-title">
              <div className="simple-text">Influencer <img src={CloverGirl} alt="Home Page image" /> <img src={CloverBoy} alt="Home Page image" /></div>
              <div className="simple-text">Marketing Made</div>
              <div className="text-underline"><div>Smarter</div><img src={TextUnderline} /></div>
            </div>
            <p>
              Discover top influencers that align with your brand, boost engagement, and maximize conversions across social media platforms
            </p>
            <div className="home-button-bar">
              <CustomTextButton onClick={()=>{
                window.location.href= "/register";
              }} className="bold-text half-width" endIcon={<FontAwesomeIcon className="btn-icon" icon={faAngleRight} />}>Get Started Today</CustomTextButton>
              {/* <MuiButton className="custom-button text-white">Book a Demo</MuiButton> */}
            </div>
          </div>
          <div className="home-right">
            <img src={HeroChart} className="hero-chart" alt="Hero Chart image" />
            <img src={HeroGirl} className="hero-girl" alt="Hero Girl Image" />
          </div>
        </div>
      </div>

      <div className="trusted-brands">
        <div className="trusted-title">Trusted By Leading Brands</div>
        <div className="brand-carousel-container">
        <Slider
            width="200px"
            duration={65}
            blurBorders={true}
            blurBorderColor={'#F2F4F7'}
        >
          {
            brandsList.slice(0, Math.ceil(brandsList.length)).map((name,index)=><Slider.Slide key={name}>
              <div className="brand-container">
              <img className={`brand-image ${name === "Unknow" && "small"}`}
                src={require(`../assets/brand-slider/${name}.png`)} alt="any" />
            </div>
            </Slider.Slide>)
          }
        </Slider>

         
        </div>
      </div>


      <div className="landing-find">
        <div className="find-title-body">
          <div className="find-title-container">
            <div className="left-bottom-icon">
              <img src={WaveLine} alt="wave" />
            </div>
            <div className="find-title">
              <div className="title-relative">Find the Perfect Creator <div className="relative-chip">Discover 1000+ Creators</div></div>
              <div className="text-combination">for<div className="text-underline"><div>Your Brand</div><img src={TextUnderline} alt="underline"/></div></div>
            </div>
            <div className="right-top-icon">
              <img src={CurlyRound} alt="curly-round"/>
            </div>
          </div>
        </div>
        <div className="slider-body">
          <Carousel
            className=""
            autoPlay
            autoPlaySpeed={2000}
            infinite={true}
            pauseOnHover
            render
            arrows={false}
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            centerMode={true}
            responsive={{
              superLargeDesktop: {
                breakpoint: { max: 4000, min: 3000 },
                items: 6
              },
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 4
              },
              tablet: {
                breakpoint: { max: 1024, min: 905 },
                items: 3
              },
              smallTablet: {
                breakpoint: {
                  max: 905, min: 667
                },
                items: 2
              },
              mobile: {
                breakpoint: { max: 667, min: 0 },
                items: 1,
                partialVisibilityGutter:10
              },

            }}
            shouldResetAutoplay
            showDots={false}
            slidesToSlide={1}
          >
            {items}
          </Carousel>

          {/* <div className="slider-container" >
            <img className="slider-img" src={Slider1} />
            <div class="slider-details">
              <h2 class="slider-name">Amara</h2>
              <div class="slider-location"><img src={LocationIcon} />Tokyo, Japan</div>
            </div>
            <div class="slider-links">
              <div className="social-link">
              <img className="social-img" src={YoutubeImg} />
                
              </div>
              <div className="social-link">
              <img className="social-img" src={InstagarmImg} />
                
              </div>
              <div className="social-link">
              <img className="social-img" src={FaceBookImg} />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="toolkit-title-container">
        <div className="find-title">
          <div className="title-relative">The Complete Toolkit for <div className="relative-chip flip toolkit">All-in-One Solution</div></div>
          <div className="text-combination">Influencer<div className="text-underline"><div>Marketing</div><img src={TextUnderline} /></div></div>
        </div>
      </div>

      {
        colorCardData.map((data) => <ColorCard data={{ ...data }} />)
      }

      <div className="toolkit-title-container second-title">
        <div className="find-title">
          <div className="title-relative">Trusted by Brands <div className="relative-chip brands">Testimonial</div></div>
          <div className="text-combination">and<div className="text-underline"><div>Creators</div><img src={TextUnderline} /></div></div>
        </div>
      </div>
      <BrandCarousel />
      <div className="trusted-brands">
        <div className="trusted-title">Trusted by Leading Brands</div>
        <div className="brand-carousel-container">
        <Slider
            width="200px"
            duration={65}
            blurBorders={true}
            blurBorderColor={'#F2F4F7'}
        >
          {
            brandsList.slice(0, Math.ceil(brandsList.length/2)).map((name,index)=><Slider.Slide key={name}>
              <div className="brand-container">
              <img className={`brand-image ${name === "Unknow" && "small"}`}
                src={require(`../assets/brand-slider/${name}.png`)} alt="any" />
            </div>
            </Slider.Slide>)
          }
        </Slider>
        <Slider
            width="200px"
            duration={65}
            blurBorders={true}
            blurBorderColor={'#F2F4F71'}
        >
          {
            brandsList.slice(Math.ceil(brandsList.length/2), brandsList.length).map((name,index)=><Slider.Slide key={name}>
               <div className="brand-container">
              <img className={`brand-image ${name === "Unknow" && "small"}`}
                src={require(`../assets/brand-slider/${name}.png`)} alt="any" />
            </div>
            </Slider.Slide>)
          }
        </Slider>
         
        </div>
      </div>

      {/* <div className="toolkit-title-container">
        <div className="find-title">
          <div className="title-relative">Unlock the Power of<div className="relative-chip flip toolkit"> Never miss a trend </div></div>
          <div className="text-combination">Social<div className="text-underline"><div>Listening</div><img src={TextUnderline} /></div></div>
        </div>
      </div> */}

      {
        socialListeningcolorCardData.map((data) => <ColorCard data={{ ...data }} />)
      }


<div className="creator-community">
          <div className="find-title-container">
            <div className="left-center-icon">
              <img height="auto" width={30} src={EllipseTop} />
            </div>
            <div className="find-title">
            <br/>
            {/* <br/> */}
              <div className="title-relative">Join the Gigl Creator <div className="relative-chip flip community">Grow with Gigl</div></div>
              <div className="text-combination"><div className="text-underline"><div>Community</div><img src={TextUnderline} /></div></div>
            </div>
            <div className="right-bottom-icon">
              <img height="auto" width={25} src={EllipseBottom} />
            </div>
          </div>
          <div className="mobile-icons">
          <img height="auto" src={EllipseTop} alt="left-icon"/>
          <img height="auto"  src={EllipseBottom} alt="right-icon" />


          </div>
          <div className="community-image">
            <img src={isMobile? CommunityMobile : CommunityImage} />
          </div>
          <div className="join-community">
            <div className="curly-wave">
              <img src={WaveLine} alt="wave-image" />

            </div>
          <MuiButton onClick={() => {
              window.location.href = "/creatorSignUp";
            }} className="custom-button grd-bg-btn community-btn" endIcon={<FontAwesomeIcon className="btn-icon" icon={faAngleRight} />}>Join the Gigl Creator Community</MuiButton>

          </div>
      </div>

      <LandingFooter />
    </div>
    // <div className="gradient-animation">
    //   <Toaster position="top-center" toastOptions={{ duration: 3000 }} />
    //   <Dialog open={open} fullWidth onClose={handleClose}>
    //     <DialogTitle>Join Our Waiting List</DialogTitle>
    //     <DialogContent>
    //       <DialogContentText>{modalText}</DialogContentText>
    //       <TextField
    //         autoFocus
    //         margin="dense"
    //         id="name"
    //         label="Email Address"
    //         type="email"
    //         fullWidth
    //         variant="standard"
    //         onChange={(e) => setEmail(e.target.value)}
    //       />

    //       <FormControl fullWidth style={{ marginTop: 20 }}>
    //         <InputLabel id="type-select-label">
    //           What best describes you?
    //         </InputLabel>
    //         <Select
    //           labelId="type-select-label"
    //           id="type-select"
    //           value={type}
    //           variant="standard"
    //           label="What best describes you?"
    //           fullWidth
    //           onChange={(e) => setType(e.target.value)}
    //         >
    //           <MenuItem value={"Influencer"}>Influencer</MenuItem>
    //           <MenuItem value={"Brand"}>Brand</MenuItem>
    //           <MenuItem value={"Agency"}>Agency</MenuItem>
    //         </Select>
    //       </FormControl>
    //     </DialogContent>
    //     <DialogActions>
    //       <Button
    //         onClick={handleSubscribe}
    //         sx={{
    //           background:
    //             "linear-gradient(130deg, white 0%, #fccb90 25%, #d57eeb 75%, #7b79be 100%)",
    //         }}
    //       >
    //         Join Now
    //       </Button>
    //     </DialogActions>
    //   </Dialog>
    //   <Main handleOpenWaitlist={handleOpenWaitlist} />
    //   <How handleOpenWaitlist={handleOpenWaitlist} />
    //   <Join handleOpenWaitlist={handleOpenWaitlist} />
    //   <div
    //     style={{ height: 5, width: "100vw", backgroundColor: "black" }}
    //   ></div>
    //   <Grid
    //     container
    //     spacing={1}
    //     sx={{
    //       width: isMobile ? "100vw" : "80vw",
    //       margin: "0 auto",
    //       textAlign: isMobile ? "center" : "left",
    //     }}
    //   >
    //     <Grid item xs={isMobile ? 12 : 4}>
    //       <div
    //         style={{ display: isMobile ? "" : "flex", alignItems: "center" }}
    //       >
    //         <Typography variant="h3"> GIGL</Typography>
    //       </div>
    //       <Typography
    //         variant="body2"
    //         style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
    //         onClick={() => navigate("/privacy")}
    //       >
    //         Privacy Policy
    //       </Typography>
    //       <Typography
    //         variant="body2"
    //         style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
    //         onClick={() => navigate("/terms")}
    //       >
    //         Terms and Conditions
    //       </Typography>
    //       <Typography
    //         variant="body2"
    //         style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
    //         onClick={() => navigate("/limited-use")}
    //       >
    //         Limited Use
    //       </Typography>
    //     </Grid>
    //     <Grid item xs={isMobile ? 12 : 4}>
    //       {isMobile ? (
    //         <p style={{ fontSize: 9 }}>Toronto, Ontario, Canada</p>
    //       ) : (
    //         <>
    //           {" "}
    //           <p style={{ fontSize: 9 }}>Toronto</p>
    //           <p style={{ fontSize: 9 }}>Ontario, Canada</p>
    //           <p style={{ fontSize: 9 }}>giglgroup.com</p>
    //         </>
    //       )}
    //     </Grid>
    //     <Grid item xs={isMobile ? 12 : 4}>
    //       <p style={{ fontSize: 9, marginRight: 15, marginLeft: 15 }}>
    //         Contact Us: support@giglgroup.com
    //       </p>
    //       <p style={{ fontSize: 8, marginRight: 15, marginLeft: 15 }}>
    //         Copyright @ Gigl 2023.
    //       </p>
    //     </Grid>
    //   </Grid>
    // </div>
  );
}

const CarousalCard = ({ data }) => {

  return <>
    <div className="slider-container" >
      <img className="slider-img" src={require(`../assets/${data.name}.jpg`)} />
      <div class="slider-details">
        <h2 class="slider-name">{data.name}</h2>
        <div class="slider-location"><img src={LocationIcon} />{data.location}</div>
      </div>
      <div class="slider-links">
        <a href="#" target="blank">
          <div className="social-link">
            <img className="social-img" src={YoutubeImg} />
          </div>
        </a>
        <a href="#" target="blank">
          <div className="social-link">
            <img className="social-img" src={InstagarmImg} />

          </div>
        </a>
        <a href="#" target="blank">
          <div className="social-link">
            <img className="social-img" src={FaceBookImg} />
          </div>
        </a>
      </div>
    </div>
  </>
}

const ColorCard = ({ data }) => {

  return <div className={`color-container ${data.color} ${data.imageSide}`}>
    <div className="info-container">
      <div className="color-title">{data.title}</div>
      {
        data.buletPoint.map((bulletPoint,index) => <div key={index}
        style={{display: "flex"}}
        className="color-bullet">
          <div className="bullet-icon"><img src={FlashIcon} /></div>
          <div className="bullet-info">
            <div className="bullet-title">{bulletPoint.title}</div>
            <div className="bullet-subtitle">{bulletPoint.subtitle}</div>
          </div>

        </div>)
      }
    </div>
    <div className="image-container" style={{alignSelf: "center", display: "flex"}}>
      <img className="colo-image" src={data.image} />
    </div>
  </div>

}