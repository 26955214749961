import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import blogpic1 from "../assets/blogpic1.PNG";
import blogpic2 from "../assets/blogpic2.PNG";

import * as React from "react";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

export default function BlogArticle(props) {
  const isMobile = useMediaQuery("(max-width:800px)");
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          float: isMobile ? "" : "left",
          maxWidth: isMobile ? "100vw" : "50vw",
          marginLeft: isMobile ? 0 : 100,
          textAlign: isMobile ? "left" : "left",
          height: isMobile ? 200 : 400,
        }}
      >
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 16 : 45,
            width: isMobile ? "80%" : "70%",
            fontWeight: 600,
            marginTop: 20,
          }}
        >
          Tips on how to go viral on social media
        </p>
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 18,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "70%",
          }}
        >
          How can businesses go Viral on Social Media? At Gigl, we are often
          asked this question, so here are 3 tips you can use to go viral this
          year.
          <br></br>
          <br></br>
          By: Gigl Expert
          <br></br>
          Date: 12/30/2023
        </p>
      </div>

      {isMobile ? (
        <p></p>
      ) : (
        <>
          <Grid
            elevation={3}
            sx={{
              height: isMobile ? 340 : 420,
              float: isMobile ? "" : "right",
              borderRadius: 10,
              marginBottom: 2,
              marginRight: 10,
            }}
          >
            <img
              src={blogpic1}
              height="100%"
              style={{
                borderRadius: 20,
                maxHeight: isMobile ? 400 : 500,
                maxWidth: isMobile ? "40vw" : "",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>
          </Grid>
        </>
      )}

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "#4158D0",
          backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
          minHeight: 30,
          marginBottom: 100,
        }}
      ></Grid>

      <div
        style={{
          float: isMobile ? "left" : "center",
          maxWidth: isMobile ? "100vw" : "80vw",
          marginLeft: isMobile ? 0 : 100,
          textAlign: isMobile ? "left" : "left",
          height: 1300,
        }}
      >
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 5 : 5,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          ➡️ Ride the Trend Wave:
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 60,
            height: isMobile ? 100 : "",

            width: isMobile ? "80%" : "80%",
          }}
        >
          Taking part in social media trends helps you increase reach, showcase
          your relevance as a brand, and spark interest from communities.
          Whether it’s a trending audio or a trending concept, quickly hopping
          onto the wave may be the answer on how to go viral in 2024.
          <br></br>
          You can follow the Gigl LinkedIn page for the latest weekly insights
          and trends on social media.
          <br></br>
          <br></br>
          <br></br>
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 12 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          🚀 Partner with Influencers and Creators to boost your reach fast and
          make your brand trusted:
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 50,
            height: isMobile ? 200 : "",

            width: isMobile ? "80%" : "80%",
          }}
        >
          Influencer marketing can help you drive massive engagement and ROI
          with a limited budget. Creators are influencers are skilled marketers
          with specific niche audiences, and as a result, they have been able to
          build engaged communities. Creators have the power to sell out
          products in minutes and build high-intent customers for your product
          and/or service.
          <br></br>
          <br></br>
          Not only do influencers have high organic reach potential, they are
          also trusted among their communities. By sharing about your brand, an
          influencer can make your brand more trusted, build engagement and have
          a much higher chance of virality
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 60,
            height: isMobile ? 100 : "",

            width: isMobile ? "80%" : "80%",
          }}
        >
          How do you launch an influencer marketing campaign?
          <br></br>
          You can find the right creator for you and launch your first
          influencer marketing campaign on Gigl: www.giglgroup.com. On the Gigl
          platform, you can partner with hundreds of influencers for your next
          marketing campaign.
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          💪 More Content & More Consistency:
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginBottom: 60,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
          }}
        >
          Rome was not built in a day and neither is your follower count. It
          takes consistency in your content. But you can do it! Video content is
          the best way to boost your content reach to a larger audience. Many
          successful creators have built massive communities by creating highly
          engaging content with simple and social-first video strategies. As a
          result, it's important to take a social-first approach when it comes
          to creating content for your social media. This means keeping your
          content within ~30s length, having a clear hook, story, and ending
          takeaway.
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
            marginTop: 50,
          }}
        >
          Want to take your business to the next level through social media
          marketing?
          <br></br>
          Sign up on Gigl to launch your next influencer marketing campaign.
        </p>

        <Button
          style={{
            display: "block",
            fontSize: isMobile ? 9 : 15,
            marginTop: 40,
            marginLeft: isMobile ? 30 : 0,
          }}
          onClick={() => navigate("/register")}
        >
          Get Started on Gigl Today
        </Button>
      </div>

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "#4158D0",
          backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
          minHeight: 30,
        }}
      ></Grid>

      <div
        style={{
          float: isMobile ? "" : "right",
          maxWidth: isMobile ? "100vw" : "40vw",
          textAlign: isMobile ? "left" : "left",
          height: isMobile ? 250 : 320,
          marginRight: isMobile ? 0 : 100,
          marginTop: 50,
          height: isMobile ? 350 : 450,
        }}
      >
        <Typography
          style={{
            fontSize: isMobile ? 15 : 18,
            marginBottom: 10,
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
          }}
        >
          Check Out the Next Article
        </Typography>

        <Typography
          style={{
            fontSize: isMobile ? 20 : 30,
            fontWeight: 600,
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
          }}
        >
          3 Tips to launch a successful social media campaign, according to top
          creators
        </Typography>

        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 25,
          }}
        >
          We asked one of the top food and lifestyle influencers in
          Canada her tips for brands to be more successful on social media. Her
          channel has over 1.4 Million followers and 1 Billion views...
        </p>
        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 10,
            fontStyle: "italic",
          }}
        >
          {" "}
          - @feeedmi | 1.4 Million Followers | Top Gigl Creator
        </p>

        <Button
          style={{
            display: "block",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: isMobile ? 9 : 15,
            marginTop: isMobile ? 20 : 40,
            marginRight: isMobile ? 0 : 300,
            marginLeft: isMobile ? 40 : 0,
          }}
          onClick={() => navigate("/blog/blogarticle2")}
        >
          Read the Full Article
        </Button>
      </div>

      {isMobile ? (
        <p></p>
      ) : (
        <>
          <img
            src={blogpic2}
            height="100%"
            style={{
              borderRadius: 20,
              maxHeight: isMobile ? 500 : 380,
              maxWidth: isMobile ? "60vw" : "65vw",
              ...(isMobile && { marginTop: 10 }),
              marginLeft: isMobile ? 80 : 100,
              marginTop: isMobile ? 35 : 20,
            }}
          ></img>
        </>
      )}

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "#4158D0",
          backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
          minHeight: 20,
        }}
      ></Grid>

      <Grid
        container
        spacing={1}
        sx={{
          width: isMobile ? "100vw" : "80vw",
          margin: "0 auto",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        <Grid item xs={isMobile ? 12 : 4}>
          <div
            style={{ display: isMobile ? "" : "flex", alignItems: "center" }}
          >
            <Typography variant="h3"> GIGL</Typography>
          </div>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/privacy")}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/terms")}
          >
            Terms and Conditions
          </Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/limited-use")}
          >
            Limited Use
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          {isMobile ? (
            <p style={{ fontSize: 9 }}>Toronto, Ontario, Canada</p>
          ) : (
            <>
              {" "}
              <p style={{ fontSize: 9 }}>Toronto</p>
              <p style={{ fontSize: 9 }}>Ontario, Canada</p>
              <p style={{ fontSize: 9 }}>giglgroup.com</p>
            </>
          )}
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <p style={{ fontSize: 9, marginRight: 15, marginLeft: 15 }}>
            Contact Us: support@giglgroup.com
          </p>
          <p style={{ fontSize: 8, marginRight: 15, marginLeft: 15 }}>
            Copyright @ Gigl 2023.
          </p>
        </Grid>
      </Grid>
    </>
  );
}
