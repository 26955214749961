import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useTheme,
  Select,
  MenuItem,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Drawer,
} from "@mui/material";
import { fetchGet, formatStat, fetchPut, getStringHash } from "../util/helpers";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import {
  IconApps,
  IconAppsFilled,
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandYoutube,
  IconBuilding,
  IconChartArrowsVertical,
  IconEdit,
  IconEye,
  IconFileDescription,
  IconInfoCircle,
  IconLink,
  IconListCheck,
  IconMapPin,
  IconMicrophone2,
  IconPresentation,
  IconSearch,
  IconUserPlus,
  IconUsers,
  IconUsersGroup,
} from "@tabler/icons-react";
import { getRandomNumber } from "../util/helpers";
import ViewsLineChart from "../components/ApexLineChart";
import UserContext from "../contexts/UserContext";
import NewOutreachTable from "./NewOutreachTable";
import CreatorCarousel from "../components/CreatorCarousel";
import WorkstreamTab from "./WorkstreamTab";
import ApplicantTab from "./ApplicantTab";
import LiveContent from "./LiveContent";
import InviteFromListDialog from "./InviteFromListDialog";

export default function CreatorCampaignPage() {
  const [campaign, setCampaign] = useState({});
  const { campaignId } = useParams();
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const [tabValue, setTabValue] = useState("details");
  const [matches, setMatches] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [applications, setApplications] = useState([]);
  const [outreach, setOutreach] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [conversions, setConversions] = useState([]);
  const [creatorCost, setCreatorCost] = useState(0);
  const [addNewListOpen, setAddNewListOpen] = useState(false);
  const [applyOpen, setApplyOpen] = useState(false);
  const navigate = useNavigate();

  const fetchCampaign = async () => {
    if (campaignId) {
      console.log("id", campaignId);
      const response = await fetchGet(`/brand/campaigns/${campaignId}`);
      if (response.result) {
        setCampaign(response.result);
      }
    }
  };

  // Creators on our platform that have applied to the campaign
  const fetchApplications = async () => {
    // setIsLoading(true);
    if (campaignId) {
      const response = await fetchGet(
        `/brand/campaigns/${campaignId}/responses`
      );
      if (response.result) {
        const creatorTemp = [...response.result];
        creatorTemp.sort(
          (a, b) =>
            (b.stats ? b.stats.totalFollowers : 0) -
            (a.stats ? a.stats.totalFollowers : 0)
        );
        // if (
        //     creatorTemp.length > 0 &&
        //     creatorTemp[0].stats &&
        //     creatorTemp[0].stats.totalFollowers > 100000
        // ) {
        //     setBestMatchCreators(creatorTemp.slice(0, 6));
        // }
        setApplications(response.result);
      }
      // setIsLoading(false);
    }
  };

  // Creators not on our platform that the brand has outreached to for this campaign.
  const fetchOutreach = () => {
    fetchGet(`/brand/outreach?campaignId=${campaignId}`)
      .then((result) => result.result)
      .then((result) => {
        setOutreach(result.map((res) => ({ ...res, id: res.email })));
      });
  };

  // Creators that have been matched to the campaign. They accepted the invite or the brand accepted their application.
  // Creators must sign up for the platform to be matched.
  // TODO: store creator object in matches
  const fetchMatches = async () => {
    if (campaignId) {
      const response = await fetchGet(`/brand/campaigns/${campaignId}/matches`);
      if (response.result) {
        for (const creator of response.result) {
          try {
            const response = await fetchGet(
              `/brand/campaigns/${campaignId}/creator/${creator.userDocId}/analytics`
            );
            if (response.result) {
              response.result.sort((a, b) => new Date(a.date) - new Date(b.date));
              creator.stats = response.result;
            }
          } catch (error) {
            console.log("error", error);
          }
        }
        setMatches(response.result);
      }
    }
  };

  const fetchRecommendations = async () => {
    if (campaignId) {
      const response = await fetchGet(
        `/brand/campaigns/${campaignId}/recommendations`
      );
      if (response.result) {
        setRecommendations(response.result);
      }
    }
  };

  useEffect(() => {
    fetchCampaign();
    fetchMatches();
    fetchRecommendations();
    fetchApplications();
    fetchOutreach();
  }, [campaignId, user]);

  const fetchAnalytics = async () => {
    const result = await fetchGet(`/brand/campaigns/${campaignId}/analytics`);
    result.result.sort((a, b) => new Date(a.date) - new Date(b.date));
    setAnalytics(result.result);
  };

  useEffect(() => {
    if (campaignId) {
      fetchAnalytics();
    }
  }, [campaignId]);

  const allData = useMemo(() => {
    const total = {};
    const result = [];
    analytics.forEach((data) => {
      const formattedDate = new Date(data.date).toISOString().slice(0, 10);
      if (total[formattedDate] === undefined) {
        total[formattedDate] = {
          views: 0,
          shares: 0,
          likes: 0,
          comments: 0,
          engagement: 0,
        };
      }
      total[formattedDate].views += data.views;
      total[formattedDate].shares += data.shares;
      total[formattedDate].likes += data.likes;
      total[formattedDate].comments += data.comments;
      total[formattedDate].engagement += data.engagement;
    });
    for (let key in total) {
      result.push([total[key], key]);
    }
    result.sort((a, b) => a[0] - b[0]);
    return [
      result.map((r) => r[0]),
      result.map((r) => new Date(r[1]).toISOString().slice(0, 10)),
    ];
  }, [analytics]);
  //   const engagementData = useMemo(() => analytics.map(data => data.engagement), [analytics])
  const allYValues = allData[0];
  const allXValues = allData[1];
  const advYValues = useMemo(() => {
    const newList = [];
    allYValues.forEach((data) => {
      const tempData = { ...data };
      for (const [key, value] of Object.entries(tempData)) {
        tempData[key] =
          creatorCost !== "0" && creatorCost
            ? parseFloat(creatorCost) / value
            : 0;
      }
      tempData["mille"] =
        creatorCost !== "0" && creatorCost
          ? parseFloat(creatorCost) / (parseFloat(data["views"]) / 1000)
          : 0;
      newList.push({ ...tempData });
    });
    return newList;
  }, [allYValues, creatorCost]);


  const creatorPerformanceHeaders = [
    "Creator",
    "Views",
    "Engagement",
    "Action",
  ];
  // const platformPerformanceHeaders = [ "Platform", "Engagement", "Engagement Rate", "Conversions", "CPC", "CPV" ];

  const platformToHeader = {
    instagram: (
      <Stack direction="row" spacing={2} alignItems="center">
        <IconBrandInstagram
          className="icon-colored"
          size="40"
          color="var(--main-text-color)"
        />
        <Typography variant="bodyregm" color="var(--main-text-color)">
          Instagram
        </Typography>
      </Stack>
    ),
    tiktok: (
      <Stack direction="row" spacing={2} alignItems="center">
        <IconBrandTiktok
          className="icon-colored"
          size="40"
          color="var(--main-text-color)"
        />
        <Typography variant="bodyregm" color="var(--main-text-color)">
          TikTok
        </Typography>
      </Stack>
    ),
    youtube: (
      <Stack direction="row" spacing={2} alignItems="center">
        <IconBrandYoutube
          className="icon-colored"
          size="40"
          color="var(--main-text-color)"
        />
        <Typography variant="bodyregm" color="var(--main-text-color)">
          Youtube
        </Typography>
      </Stack>
    ),
    facebook: (
      <Stack direction="row" spacing={2} alignItems="center">
        <IconBrandFacebook
          className="icon-colored"
          size="40"
          color="var(--main-text-color)"
        />
        <Typography variant="bodyregm" color="var(--main-text-color)">
          Facebook
        </Typography>
      </Stack>
    ),
  };
  console.log("allData", allData, applications);

  const tabs = [
    // {
    //     name: "Overview",
    //     value: "overview",
    // },
    { name: "Details", value: "details" },
    { name: "Workstream", value: "workstream" },
    { name: "Analytics", value: "analytics" },
    { name: "Live Content", value: "live" },
  ];
  const copyInviteLink = async ()=>{
    try{
      const invitationLink = `${window.location.origin}/match/${campaignId}`; 
      await navigator.clipboard.writeText(invitationLink);
      toast.success("Link copied!")
    }catch(e){
      toast.error("Failed to copy link!");
    }
  }

  const handleAddToLists = () => {
    // setAddNewListOpen(true);
  }
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
            <Toaster position="top-center" toastOptions={{ duration: 3000 }} />
           <Drawer open={applyOpen} onClose={()=>{setApplyOpen(false)}} anchor="right">
            <Stack direction="column" spacing={2} sx={{padding: "3%"}}>
              <Box style={{borderRadius: "12px", background: "var(--color-gradient)", width: "100%", height: 80, textAlign: "center", alignItems: "center", justifyContent: "center", display: "flex"}}>
                <Typography variant="h5" color={"white"}>Apply for this campaign</Typography>
              </Box>
              <Typography variant="body2" color={"var(--main-text-color)"}>
                Partnership Rates: What is your proposed rate for this campaign? (USD)
              </Typography>
              <TextField label="Rate"/>
              <Typography variant="body2" color={"var(--main-text-color)"}>
                Campaign Concept (Optional)
              </Typography>
              <TextField label="Concept" multiline rows={4} />
              <Typography variant="body2" color={"var(--main-text-color)"}>
                Shipping Address (Optional)
              </Typography>
              <TextField label="Address" multiline rows={4} />
              <Typography variant="body2" color={"var(--main-text-color)"}>
                Additional Information: Anything else you'd like to share? (Optional)
              </Typography>
              <TextField label="Additional Information" multiline rows={4} />
              <Button variant="contained" className="gradient-button" fullWidth>Submit</Button>
            </Stack>
           </Drawer>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          height: "160px",
          backgroundColor: "white",
          paddingTop: "24px",
          paddingX: "20px",
        }}
      >
        <Stack direction="column" justifyContent="space-between" height="100%">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={2}>
              <img
                className="campaign-image"
                src={campaign.image}
                alt={campaign.name}
              />
              <Stack direction="column" spacing={1}>
                <Typography variant="h3" color={"var(--main-text-color)"}>
                  {campaign.name}
                </Typography>
               
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Tooltip title={<Typography variant="body2">Apply to this campaign.</Typography>}>
                <Button
                  onClick={()=>{setApplyOpen(true)}}
                variant="contained"
                className="gradient-button"
                startIcon={<IconUserPlus className="icon" size="18" />}
              >
                  Apply
                </Button>
              </Tooltip>
              
            </Stack>
          </Stack>
          <Tabs
            value={tabValue}
            onChange={(e, value) => setTabValue(value)}
            indicatorColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                className={tabValue === tab.value ? "gradient-text" : ""}
                label={tab.name}
                value={tab.value}
                disabled
              />
            ))}
          </Tabs>
        </Stack>
      </Box>
      {tabValue === "analytics" && (
        <Stack direction="column" spacing={2} sx={{ padding: "3%" }}>
          <Typography variant="h3" color={"var(--main-text-color)"}>
            Analytics
          </Typography>
          <Stack direction="row" spacing={1}>
            <Box className="box">
              <Stack
                direction="column"
                spacing={3}
                height="100%"
                justifyContent="space-between"
              >
                <Typography
                  variant="bodyregm"
                  color="var(--body-text-color-600)"
                >
                  Total Reach
                </Typography>
                <Typography variant="h2">
                  {formatStat(allYValues[allYValues.length - 1]?.views)}
                </Typography>
              </Stack>
            </Box>
            <Box className="box">
              <Stack
                direction="column"
                spacing={3}
                height="100%"
                justifyContent="space-between"
              >
                <Typography
                  variant="bodyregm"
                  color="var(--body-text-color-600)"
                >
                  Engagement
                </Typography>
                <Typography variant="h2">
                  {formatStat(allYValues[allYValues.length - 1]?.engagement)}
                </Typography>
              </Stack>
            </Box>
            <Box className="box">
              <Stack
                direction="column"
                spacing={3}
                height="100%"
                justifyContent="space-between"
              >
                <Typography
                  variant="bodyregm"
                  color="var(--body-text-color-600)"
                >
                  Engagement Rate
                </Typography>
                <Typography variant="h2">
                  {(
                    (allYValues[allYValues.length - 1]?.engagement /
                      allYValues[allYValues.length - 1]?.views) *
                    100
                  ).toFixed(2)}
                  %
                </Typography>
              </Stack>
            </Box>
            <Box className="box">
              <Stack
                direction="column"
                spacing={3}
                height="100%"
                justifyContent="space-between"
              >
                <Typography
                  variant="bodyregm"
                  color="var(--body-text-color-600)"
                >
                  Partners
                </Typography>
                <Typography variant="h2">{matches.length}</Typography>
              </Stack>
            </Box>
          </Stack>
          <Stack className="box" direction="column" spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box
                  className="gradient-circle"
                  sx={{
                    padding: "8px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <IconUsers size="20" className="icon" />
                </Box>
                <Typography variant="h4" color={"var(--main-text-color)"}>
                  Creator Performance
                </Typography>
              </Stack>
            </Stack>
            <TableContainer>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    {creatorPerformanceHeaders.map((header) => (
                      <TableCell>
                        <Typography
                          variant="bodysm"
                          color="var(--body-text-color-600)"
                        >
                          {header}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matches &&
                    matches
                      .filter((creator) => creator.name)
                      .map((creator) => {
                        const application = applications.find(
                          (app) => app.userDocId === creator.userDocId
                        );
                        console.log("application", application, creator);
                        return (
                          <TableRow>
                            <TableCell align="left" width="30%">
                              <Stack direction="row" spacing={2}>
                                <img
                                  className="profile-image"
                                  src={
                                    creator?.images?.length > 0
                                      ? creator?.images[0]
                                      : `../gradient${getStringHash(creator?.name, 10)}.png`
                                  }
                                  alt={creator.name}
                                />
                                <Stack direction="column" spacing={0}>
                                  <Typography
                                    variant="bodyregm"
                                    color="var(--main-text-color)"
                                  >
                                    {creator.name}
                                  </Typography>
                                  <Typography
                                    variant="bodysm"
                                    color="var(--body-text-color-600)"
                                  >
                                    {application?.stats?.channels?.length > 0
                                      ? application?.stats?.channels[0]?.url
                                      : "-"}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </TableCell>
                            <TableCell align="left" width="12%">
                              <Typography
                                variant="bodysm"
                                color="var(--main-text-color)"
                              >
                                {creator?.stats?.length === 0 ? "-" : creator?.stats[creator?.stats?.length - 1]?.views}
                              </Typography>
                            </TableCell>
                            <TableCell align="left" width="12%">
                              <Typography
                                variant="bodysm"
                                color="var(--main-text-color)"
                              >
                                {creator?.stats?.length === 0 ? "-" : creator?.stats[creator?.stats?.length - 1]?.engagement}
                              </Typography>
                            </TableCell>

                            <TableCell align="left" width="12%">
                              <Button
                                variant="outlined"
                                className="outlined-button"
                                color="info"
                              >
                                View Insights
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
          {/* <Stack className="box" direction="column" spacing={2}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                                <IconApps size="20" className="icon"/>
                            </Box>
                            <Typography variant="h4" color={"var(--main-text-color)"}>Platform Performance</Typography>
                        </Stack>
                    </Stack>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    {platformPerformanceHeaders.map((header) => (
                                    <TableCell>
                                        <Typography variant="bodysm" color="var(--body-text-color-600)">{header}</Typography>
                                    </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {campaign && campaign.platforms.map((platform) =>
                                    <TableRow>
                                        <TableCell align="left" width="30%">
                                            {platformToHeader[platform]}
                                        </TableCell>
                                        <TableCell align="left" width="12%">
                                            <Typography variant="bodysm" color="var(--main-text-color)">600</Typography>
                                        </TableCell>
                                        <TableCell align="left" width="12%">
                                            <Typography variant="bodysm" color="var(--main-text-color)">45%</Typography>
                                        </TableCell>
                                        <TableCell align="left" width="12%">
                                            <Typography variant="bodysm" color="var(--main-text-color)">549</Typography>
                                        </TableCell>
                                        <TableCell align="left" width="12%">
                                            <Typography variant="bodysm" color="var(--main-text-color)">$0.05</Typography>
                                        </TableCell>
                                        <TableCell align="left" width="12%">
                                            <Typography variant="bodysm" color="var(--main-text-color)">$0.05</Typography>
                                        </TableCell>
                                    </TableRow> 
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack> */}
          <Stack direction="column" spacing={2} className="box">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box
                  className="gradient-circle"
                  sx={{
                    padding: "8px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <IconChartArrowsVertical size="20" className="icon" />
                </Box>
                <Typography variant="h4" color={"var(--main-text-color)"}>
                  Views
                </Typography>
              </Stack>
            </Stack>
            <ViewsLineChart data={allData} dataKey="views" />
          </Stack>
          <Stack direction="column" spacing={2} className="box">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box
                  className="gradient-circle"
                  sx={{
                    padding: "8px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <IconChartArrowsVertical size="20" className="icon" />
                </Box>
                <Typography variant="h4" color={"var(--main-text-color)"}>
                  Engagement
                </Typography>
              </Stack>
            </Stack>
            <ViewsLineChart data={allData} dataKey="engagement" />
          </Stack>
        </Stack>

      )}
      {tabValue === "details" && (
        <Stack direction="column" spacing={2} sx={{ padding: "3%" }}>
          <Typography variant="h3" color={"var(--main-text-color)"}>
            Details
          </Typography>
          <Stack className="box" direction="column" spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box
                  className="gradient-circle"
                  sx={{
                    padding: "8px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <IconFileDescription size="20" className="icon" />
                </Box>
                <Typography variant="h4" color={"var(--main-text-color)"}>
                  Description
                </Typography>
              </Stack>
            </Stack>
            <Typography variant="bodysm" color="var(--body-text-color-600)">
              {campaign.description}
            </Typography>
            <Divider />
            <Stack direction="row" width="100%" spacing={2}>
              <Stack direction="row" spacing={2} width="25%">
                <Box
                  className="gradient-circle-light"
                  sx={{
                    padding: "9px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <IconApps
                    size="25"
                    className="icon-colored"
                    color={theme.palette.secondary.main}
                  />
                </Box>
                <Stack direction="column" spacing={0}>
                  <Typography
                    variant="bodysm"
                    color="var(--body-text-color-600)"
                  >
                    Platforms
                  </Typography>
                  <Typography variant="bodym" color="var(--main-text-color)">
                    TikTok, Instagram
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" width="25%" spacing={2}>
                <Box
                  className="gradient-circle-light"
                  sx={{
                    padding: "9px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <IconUsersGroup
                    size="25"
                    className="icon-colored"
                    color={theme.palette.secondary.main}
                  />
                </Box>
                <Stack direction="column" spacing={0}>
                  <Typography
                    variant="bodysm"
                    color="var(--body-text-color-600)"
                  >
                    Creator Levels
                  </Typography>
                  <Typography variant="bodym" color="var(--main-text-color)">
                    Micro, Macro
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" width="25%" spacing={2}>
                <Box
                  className="gradient-circle-light"
                  sx={{
                    padding: "9px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <IconBuilding
                    size="25"
                    className="icon-colored"
                    color={theme.palette.secondary.main}
                  />
                </Box>
                <Stack direction="column" spacing={0}>
                  <Typography
                    variant="bodysm"
                    color="var(--body-text-color-600)"
                  >
                    Industry
                  </Typography>
                  <Typography variant="bodym" color="var(--main-text-color)">
                    Travel, Beauty
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" width="25%" spacing={2}>
                <Box
                  className="gradient-circle-light"
                  sx={{
                    padding: "9px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <IconMapPin
                    size="25"
                    className="icon-colored"
                    color={theme.palette.secondary.main}
                  />
                </Box>
                <Stack direction="column" spacing={0}>
                  <Typography
                    variant="bodysm"
                    color="var(--body-text-color-600)"
                  >
                    Region
                  </Typography>
                  <Typography variant="bodym" color="var(--main-text-color)">
                    USA
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack className="box" direction="column" spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                width="100%"
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    className="gradient-circle"
                    sx={{
                      padding: "8px",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    <IconListCheck size="20" className="icon" />
                  </Box>
                  <Typography variant="h4" color={"var(--main-text-color)"}>
                    Deliverables
                  </Typography>
                </Stack>
                {/* Add progress bar once deliverables are changed to a list format with specific fields */}
                {/* <Stack sx={{ width: '15%'}}>
                                    <LinearProgress variant="determinate" value="50" color="success" sx={{borderRadius: 5, height: "6px"}}/>
                                </Stack>
                                <Typography variant="bodyxs" color="var(--body-text-color-600)">1/3 completed</Typography> */}
              </Stack>
              {/* <Button className="outlined-button" variant="outlined" startIcon={<IconEdit className="icon-colored" size="18" color={theme.palette.primary.main}/>}>Edit Deliverables</Button> */}
            </Stack>
            <Divider />
            <Typography variant="bodysm" color="var(--body-text-color-600)" style={{whiteSpace: "pre-wrap"}}>
              {Array.isArray(campaign.deliverables) ? campaign.deliverables.join("\n") : campaign.deliverables}
            </Typography>
            {/* Create table once deliverables are changed to a list format with specific fields */}
            {/* <TableContainer>
                            <Table sx={{ minWidth: 650 }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer> */}
          </Stack>
          <Stack direction="row" spacing={2}>
            <Stack className="box" direction="column" spacing={2} width="50%">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box
                  className="gradient-circle"
                  sx={{
                    padding: "8px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <IconPresentation size="20" className="icon" />
                </Box>
                <Typography variant="h4" color={"var(--main-text-color)"}>
                  Talking Points
                </Typography>
              </Stack>
              <Divider />
              {campaign.talking_points &&
                campaign.talking_points.split("\n").map((point) => (
                  <Stack direction="row" gap={3}>
                    <Box sx={{ width: "30px" }}>
                      <IconMicrophone2
                        className="icon-colored"
                        size="25"
                        color={theme.palette.secondary.light}
                      />
                    </Box>
                    <Typography
                      variant="bodysm"
                      color="var(--body-text-color-600)"
                    >
                      {point.replace(/^- /, "")}
                    </Typography>
                  </Stack>
                ))}
            </Stack>
            <Stack className="box" direction="column" spacing={2} width="50%">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box
                  className="gradient-circle"
                  sx={{
                    padding: "6px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <IconInfoCircle size="24" className="icon" />
                </Box>
                <Typography variant="h4" color={"var(--main-text-color)"}>
                  Resources
                </Typography>
              </Stack>
              <Divider />
              <Stack direction="column" spacing={2}>
                <Link href="/campaigns/faq" target="_blank" style={{ textDecoration: "none", display: "flex", alignItems: "center", gap: "5px" }}>

                  <Typography variant="bodysm" color="var(--body-text-color-600)">
                    Tutorials & FAQ
                  </Typography>
                  <IconLink size="20" className="icon-colored"  color="var(--main-text-color)"/>
                </Link>
<Stack direction="row" alignItems="center" spacing={2} style={{cursor: "pointer"}}>
<Typography variant="bodysm" color="var(--body-text-color-600)">
You're a rockstar! Congrats on launching your campaign. Here are some tips to get your started

                  </Typography>
                  <IconInfoCircle size="20" className="icon-colored"  color="var(--main-text-color)"/>
                  </Stack>
              </Stack>
              {/* Add links to resources here */}
            </Stack>
          </Stack>
        </Stack>
      )}
      {tabValue === "outreach" && (
        <Stack direction="column" spacing={2} sx={{ padding: "3%" }}>
          <InviteFromListDialog open={addNewListOpen} onClose={() => setAddNewListOpen(false)} handleAddToLists={handleAddToLists} campaignId={campaignId}/>
          <Typography variant="h3" color={"var(--main-text-color)"}>
            Outreach
          </Typography>
          <Stack direction="column" spacing={2} className="box">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between" width="100%">
                <Stack direction="row" alignItems="center" spacing={2}>
                <Box
                  className="gradient-circle"
                  sx={{
                    padding: "8px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <IconUsers size="20" className="icon" />
                </Box>
                <Typography variant="h4" color={"var(--main-text-color)"}>
                  Invite Creators
                </Typography>
                </Stack>
                <Button
                startIcon={<IconUserPlus className="icon" size="18" style={{color: "black"}} />}
                onClick={() => setAddNewListOpen(true)}
                variant="outlined" className="outlined-button" color="info">Invite From My List</Button>
              </Stack>
              
            </Stack>
            <NewOutreachTable campaignId={campaignId}/>
          </Stack>
          <CreatorCarousel
            creators={recommendations}
            title="Recommended Creators"
          />
        </Stack>
      )}
      {tabValue === "workstream" && <WorkstreamTab matches={matches} campaign={campaign} />}
      {tabValue === "live" && <LiveContent />}
    </Box>
  );
}
