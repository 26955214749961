
import "./App.css";
import 'react-multi-carousel/lib/styles.css';
import Home from "./Home/Home";
import Scraper from "./Admin/Scraper";
import { CssBaseline, Toolbar, Typography } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { cloneElement, useContext, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "animate.css";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import Campaigns from "./Campaigns/Campaigns";
import Campaign from "./Campaign/Campaign";
import Workstream from "./Workstream/Workstream";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ProductRedirect } from "./ProductRedirect/ProductRedirect";
import Pricing from "./Pricing/Pricing";
import Login from "./Login/Login";
import Register from "./Register/Register";
import Setup from "./Setup/Setup";
import Lottie from "react-lottie";
import * as animationData from "./assets/ai-animation.json";
import LoadingContext from "./contexts/LoadingContext";
import { TypeAnimation } from "react-type-animation";
import Privacy from "./Privacy";
import Terms from "./Terms";
import TiktokOauth from "./Oauth/Tiktok";
import YoutubeOAuth from "./Oauth/Youtube";
import UserContext from "./contexts/UserContext";
import { logout } from "./util/helpers";
import FacebookOauth from "./Oauth/Facebook";
import Unsubscribe from "./Mailing/Unsubscribe";
import BlogArticle from "./Blog/Blog";
import BlogArticle3 from "./Blog/BlogArticle3";
import BlogArticle4 from "./Blog/BlogArticle4";
import BlogArticle2 from "./Blog/BlogArticle2";
import CreatorHome from "./CreatorJourney/CreatorHome";
import CreatorSignUp from "./Register/CreatorSignUp";
import FrequentlyAskedQuestions from "./Campaigns/FAQ";
import AnalyticsDashboard from "./Campaigns/AnalyticsDashboard";
import SocialTrends from "./Campaigns/SocialTrends";
import CreatorDiscovery from "./Campaigns/CreatorDiscovery";
import MyAccount from "./Campaigns/MyAccount";
import LearnMoreOutreach from "./Campaign/LearnMoreOutreach";
import AutoInviteLogin from "./AutoInvite/AutoInviteLogin";
import AutoInviteRegister from "./AutoInvite/AutoInviteRegister";
import CreatorMyAccount from "./Creators/CreatorMyAccount";


import LimitedUse from "./LimitedUse";
import FAQ from "./FAQ";
import TempAddAnalytics from "./TempAddAnalytics";
import { AppBlockingRounded } from "@mui/icons-material";
import CreatorLogin from "./Login/CreatorLogin";
import Creators from "./Creators/Creators";
import CreatorsWorkstream from "./Creators/CreatorsWorkstream";
import Landscape from "./Competitive/Landscape";
import CreatorSetup from "./CreatorSetup/CreatorSetup";
import Favorites from "./Favorites/Favorites";
import { Layout, ScrollToTop } from "./components";
import Dashboard from "./Dashboard/Dashboard";
import NewCampaignPage from "./Campaign/NewCampaignPage";
import CreateCampaign from "./Campaign/NewCreateCampaign";
import BlogArticle5 from "./Blog/BlogArticle5";
import CreatorCampaignPage from "./Campaign/CreatorCampaignPage";



function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 2 : 0,
  });
}

function LoadingScreen(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { loadingText } = props;
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(0, 0, 0, 0.65)",
        position: "fixed",
        zIndex: 100000000,
        top: 0,
      }}
    >
      <div
        style={{
          margin: "auto",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: "absolute",
          textAlign: "center",
          display: "table",
        }}
      >
        <Lottie
          options={defaultOptions}
          style={{ opacity: 0.8, margin: "auto" }}
          height={300}
          width={300}
          isStopped={false}
          isPaused={false}
        />
        <Typography sx={{ color: "white", marginTop: -5 }} variant="subtitle1">
          <TypeAnimation sequence={[loadingText]} speed={55} cursor={true}>
            {" "}
          </TypeAnimation>
        </Typography>
      </div>
    </div>
  );
}

function ScreenSizeAlert(props) {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(0, 0, 0, 1)",
        position: "fixed",
        zIndex: 100000000,
        top: 0,
      }}
    >
      <div
        style={{
          margin: "auto",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: "absolute",
          textAlign: "center",
          display: "table",
        }}
      >
        <Typography sx={{ color: "white", marginTop: -5 }} variant="subtitle1">
          Your screen size is too small! For an optimal experience please view
          this page on your computer.
        </Typography>
      </div>
    </div>
  );
}

const THEME = createTheme({
  typography: {
    fontFamily: `Inter`,
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontWeightSemiBold: 600,
    button: {
      textTransform: 'none'
    },
    h2: {
      fontSize: 30,
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "120%",
      letterSpacing: "-1px",
      color: "var(--main-text-color)",
    },
    h3: {
      fontSize: 24,
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "140%",
      letterSpacing: "-1px",
      color: "var(--main-text-color)",
    },
    h4: {
      fontSize: 20,
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "140%",
      letterSpacing: "-0.5px",
      color: "var(--main-text-color)",
    },
    h5: {
      fontSize: 18,
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "140%", /* 25.2px */
      letterSpacing: "-0.5px",
      color: "var(--main-text-color)",
    },
    bodyreg: {
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "140%",
      letterSpacing: "-0.3px",
    },
    bodyregm: {
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "140%",
      letterSpacing: "-0.3px",
    },
    bodysm: {
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "140%",
      letterSpacing: "-0.3px",
    },
    bodysmm: {
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "140%",
      letterSpacing: "-0.3px",
    },
    bodyxs: {
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "140%",
      letterSpacing: "-0.3px",
    }
  },
  palette: {
    primary: {
      main: "#1E22FB", //600 shade from design colour palette
      light: "#7375FD", //400
      dark: "#0306BA",//800
    },
    secondary: {
      main: "#8C1ED9", //600 new color not in design palette, added in app.css
      light: "#D9BFFF", //100
      dark: "#6610A0", //800
    },
    info: {
      main: "#475467", // neutrals from colour palette
      light: "#F2F4F7", // 100
      dark: "#344054",
    },
    success: {
      main: "#039855",
      light: "#D1FADF", // 100
      dark: "#05603A",
    },
    warning: {
      main: "#D97706",
      light: "#FBBF24",
      dark: "#92400E",
    },
    error: {
      main: "#DC2626",
      light: "#F87171",
      dark: "#7F1D1D",
    }
  },
});

const ProtectedRoute = ({ redirectPath = "/login", children }) => {
  const { authed, user, isInit } = useContext(UserContext);
  if (!isInit) {
    return null;
  }
  console.log("ProtectedRoute", authed, user, !authed && !user.plan && isInit, authed && !user.plan && isInit && user.type !== "creator")
  
  if (!authed && !user.plan && isInit) {
    console.log("redirecting to", redirectPath)
    return <Navigate to={redirectPath} replace />;
  } else if (authed && !user.plan && isInit && user.type !== "creator") {
    console.log("redirecting to setup")
    return <Navigate to={"/setup"} replace />;
  }
  console.log("going to children")
  return children ? <ScrollToTop><Layout>{children}</Layout></ScrollToTop> : <Outlet />;
};

const SetupRoute = ({ redirectPath = "/login", children }) => {
  const { authed, user } = useContext(UserContext);
  console.log("setup route", authed)
  if (!authed) {
    return <Navigate to={redirectPath} replace />;
  } else if (authed && user.plan) {
    return <Navigate to={user.type === "creator"? "/creators" : "/campaigns"} replace />;
  }

  return children ? <ScrollToTop>{children}</ScrollToTop> : <Outlet />;
};

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <ScrollToTop><Home /> </ScrollToTop>
    },
    {
      path: "login",
      element: <ScrollToTop><Login /> </ScrollToTop>
    },
    {
      path: "register",
      element: <ScrollToTop><Register /> </ScrollToTop>
    },
    {
      path: "setup",
      element: (
        <SetupRoute>
          <Setup />
        </SetupRoute>
      ),
    },
    {
      path: "admin/scraper",
      element: <ProtectedRoute><Scraper /> </ProtectedRoute>
    },
    {
      path: "campaigns/:campaignId/workstream",
      element: (
        <ProtectedRoute>
          <Workstream />
        </ProtectedRoute>
      ),
    },
    {
      path: "campaigns/:campaignId",
      element: (
        <ProtectedRoute>
          <NewCampaignPage />
          {/* <Campaign /> */}
        </ProtectedRoute>
      ),
    },
    {
      path: "/outreach/:creatorId",
      element: (
        <ProtectedRoute>
          <LearnMoreOutreach />
        </ProtectedRoute>
      ),
    },
    {
      path: "campaigns/FAQ",
      element: (
        <ProtectedRoute>
          <FrequentlyAskedQuestions />
        </ProtectedRoute>
      ),
    },
    {
      path: "campaigns/analyticsdashboard",
      element: (
        <ProtectedRoute>
          <AnalyticsDashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: "campaigns/socialtrends",
      element: (
        <ProtectedRoute>
          <SocialTrends />
        </ProtectedRoute>
      ),
    },
    {
      path: "campaigns/creatordiscovery",
      element: (
        <ProtectedRoute>
          <CreatorDiscovery />
        </ProtectedRoute>
      ),
    },
    {
      path: "campaigns/account",
      element: (
        <ProtectedRoute>
          <MyAccount />
        </ProtectedRoute>
      ),
    },
    {
      path: "campaigns",
      element: (
        <ProtectedRoute>
          <Campaigns />
        </ProtectedRoute>
      ),
    },
    {
      path: "dashboard",
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: "product/:productId",
      element: <ScrollToTop><ProductRedirect /> </ScrollToTop>
    },
    {
      path: "pricing",
      element: <ScrollToTop><Pricing /> </ScrollToTop>
    },
    // {
    //   path: "creatorhome",
    //   element: <ScrollToTop><CreatorHome /> </ScrollToTop>
    // },
    {
      path: "/creatorsignup",
      element: <ScrollToTop><CreatorSignUp /> </ScrollToTop>
    },
    {
      path: "/creatorlogin",
      element: <ScrollToTop><CreatorLogin /> </ScrollToTop>
    },
    {
      path: "/creators",
      element: (
        <ProtectedRoute>
          <Creators />
        </ProtectedRoute>
      )
    },
    {
      path: "creators/:campaignId",
      element: (
        <ProtectedRoute>
          <CreatorCampaignPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "creators/:campaignId/workstream",
      element: (
        <ProtectedRoute>
          <CreatorsWorkstream />
        </ProtectedRoute>
      ),
    },
    {
      path: "creatorsetup",
      element: (
        <SetupRoute redirectPath="/creatorlogin">
          <CreatorSetup />
        </SetupRoute>
      ),
    },
    {
      path: "creators/account",
      element: (
        <ProtectedRoute>
          <CreatorMyAccount />
        </ProtectedRoute>
      ),
    },
    // {
    //   path: "/creatorsignup/creatordashboard",
    //   element: <ScrollToTop><creatorDashboard/> </ScrollToTop>
    // },

    {
      path: "privacy",
      element: <ScrollToTop><Privacy /> </ScrollToTop>
    },
    {
      path: "terms",
      element: <ScrollToTop><Terms /> </ScrollToTop>
    },
    {
      path: "limited-use",
      element: <ScrollToTop><LimitedUse /> </ScrollToTop>
    },
    {
      path: "blog",
      element: <ScrollToTop><FAQ /> </ScrollToTop>
    },
    {
      path: "blog/2025-influencer-marketing-insights",
      element: <ScrollToTop><BlogArticle5 /> </ScrollToTop>
    },
    {
      path: "blog/blogarticle",
      element: <ScrollToTop><BlogArticle /> </ScrollToTop>
    },
    {
      path: "blog/blogarticle3",
      element: <ScrollToTop><BlogArticle3 /> </ScrollToTop>
    },
    {
      path: "blog/blogarticle4",
      element: <ScrollToTop><BlogArticle4 /> </ScrollToTop>
    },
    {
      path: "blog/blogarticle2",
      element: <ScrollToTop><BlogArticle2 /> </ScrollToTop>
    },
    {
      path: "oauth/youtube",
      element: <ScrollToTop><YoutubeOAuth /> </ScrollToTop>
    },
    {
      path: "oauth/facebook",
      element: <ScrollToTop><FacebookOauth /> </ScrollToTop>
    },
    {
      path: "oauth",
      element: <ScrollToTop><TiktokOauth /> </ScrollToTop>
    },
    {
      path: "oauth/tiktok",
      element: <ScrollToTop><TiktokOauth /> </ScrollToTop>
    },
    {
      path: "mailing/unsubscribe",
      element: <ScrollToTop><Unsubscribe /> </ScrollToTop>
    },
    {
      path: "analytics/update",
      element: <ScrollToTop><TempAddAnalytics /> </ScrollToTop>
    },
    {
      path: "competitive/landscape",
      element: (
        <ProtectedRoute>
          <Landscape />
        </ProtectedRoute>
      ),
    },
    {
      path: "match/:campaignId",
      element: <ScrollToTop><AutoInviteLogin /> </ScrollToTop>
    },
    {
      path:"/match/register/:campaignId",
      element: <ScrollToTop><AutoInviteRegister /> </ScrollToTop>
    },
    {
      path:"/favorites",
      element: <ProtectedRoute>
        <Favorites />
      </ProtectedRoute>
    },
    {
      path: "/campaigns/create",
      element: <ProtectedRoute>
        <CreateCampaign />
      </ProtectedRoute>
    },
    {
      path: "/campaigns/:campaignId/edit",
      element: <ProtectedRoute>
        <CreateCampaign />
      </ProtectedRoute>
    }
  ]);
  const { isLoading, loadingText } = useContext(LoadingContext);
  const isDesktop = useMediaQuery("(min-width:1000px)");

  
  return (
    <div>
      {isLoading && <LoadingScreen loadingText={loadingText} />}
      {!isDesktop &&
        !window.location.pathname.startsWith("/oauth") &&
        window.location.pathname !== "/" &&
        window.location.pathname !== "/pricing" &&
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/register" &&
        window.location.pathname !== "/blog" &&
        window.location.pathname !== "/creatorSetup" &&
        // window.location.pathname !== "/creatorhome" &&
        window.location.pathname !== "/setup" &&
        window.location.pathname !== "/mailing/unsubscribe" &&
        window.location.pathname !== "/setup" &&
        window.location.pathname !== "/blog/blogarticle" &&
        window.location.pathname !== "/setup" &&
        window.location.pathname !== "/blog/blogarticle3" &&
        window.location.pathname !== "/setup" &&
        window.location.pathname !== "/blog/blogarticle4" &&
        window.location.pathname !== "/setup" &&
        window.location.pathname !== "/blog/blogarticle2" && 
        window.location.pathname !== "blog/2025-influencer-marketing-insights" && 
        window.location.pathname !== "/creatorsignup" &&  (
          <ScreenSizeAlert />
        )}

      <ThemeProvider theme={THEME}>
        <CssBaseline />
          <div>
            <RouterProvider router={router} />
          </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
