import { Box, Drawer, Typography, Divider, Chip, Link } from "@mui/material";
import { capitalizeFirstLetter, fetchPost, formatStat, getRandomNumber, getRateRange, getStringHash, parseFollowers } from "../util/helpers";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandYoutube,
} from "@tabler/icons-react";
import { memo } from "react";
import { Button } from "../components";

function InsightDrawer({ open, onClose, creator }) {
  console.log("creator", creator);
    if (!creator) return null;


  return (
    <Drawer open={open} onClose={onClose} anchor="right" style={{zIndex: 100000}}>
      <Box style={{ minWidth: 300, width: "30vw", padding: 10 }}>
        <Box style={{ paddingBottom: 10, paddingTop: 20 }}>
          <Typography variant="h5">Creator Insights</Typography>
        </Box>
        <Divider />
        <Box className="creator-insights-container" display="flex" flexDirection="column" gap={1} justifyContent={"flex-start"} alignItems={"flex-start"}>
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <img
              className="profile-image-large"
              
              src={
                creator?.images?.length > 0
                  ? creator?.images[0]
                  : `../gradient${getStringHash(creator?.name, 10)}.png`
              }
              alt={creator.name}
            />
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="h4">{creator?.name}</Typography>
              
            </Box>
          </Box>
      
        </Box>
       
        <Box style={{ paddingBottom: 10, paddingTop: 10 }}>
          <Typography variant="h5">Overview</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          gap={1}
          sx={{
            border: "1px solid var(--neutral-200)",
            borderRadius: 2,
            padding: 2,
          }}
        >
          {creator?.stats?.length === 0 && <Typography variant="body1">No stats available yet</Typography>}
        
      </Box>
      <br/>


    

        </Box>
     
    </Drawer>
  );
}

export default memo(InsightDrawer);
