import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import blogpic5 from "../assets/blogpic6.PNG";

import * as React from "react";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

export default function BlogArticle5(props) {
  const isMobile = useMediaQuery("(max-width:800px)");
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          float: isMobile ? "" : "left",
          maxWidth: isMobile ? "100vw" : "50vw",
          marginLeft: isMobile ? 0 : 100,
          textAlign: isMobile ? "center" : "left",
          height: 200,
        }}
      >
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 16 : 40,
            width: isMobile ? "80%" : "85%",
            fontWeight: 600,
            marginTop: 50,
          }}
        >
          2025 Influencer Marketing Insights & Trends
        </p>
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 14,
            marginTop: isMobile ? 10 : 25,
            width: isMobile ? "80%" : "70%",
          }}
        >
          Read below for some of the latest influencer marketing trends and insights this year. Follow the Gigl blog to get the latest insights across the influencer marketing industry
          <br></br>
          <br></br>
          By: Gigl Expert
          <br></br>
          Date: 02/07/2025
        </p>
      </div>

      {isMobile ? (
        <p></p>
      ) : (
        <>
          <Grid
            elevation={3}
            sx={{
              height: isMobile ? 340 : 420,
              float: isMobile ? "" : "right",
              borderRadius: 10,
              marginBottom: 2,
              width: "35vw",
              marginRight: 10,
            }}
          >
            <img
              src={blogpic5}
              height="100%"
              style={{
                borderRadius: 20,
                marginTop: 40,
                maxHeight: isMobile ? 400 : 350,
                maxWidth: isMobile ? "40vw" : "",
                ...(isMobile && { marginTop: 40 }),
              }}
            ></img>
          </Grid>
        </>
      )}

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "black",
          minHeight: 20,
          marginBottom: 100,
          marginTop: 20,
        }}
      ></Grid>

      <div
        style={{
          float: isMobile ? "left" : "center",
          maxWidth: isMobile ? "100vw" : "80vw",
          marginLeft: isMobile ? 0 : 100,
          textAlign: isMobile ? "left" : "left",
          height: isMobile ? 1800 : 2000,
        }}
      >
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 50,
            height: isMobile ? 220 : "",

            width: isMobile ? "80%" : "80%",
          }}
        >
          Social media is the most viewed channel today and the content that audiences are watching is primarily content made by influencers and creators. As a result, the Influencer Marketing industry is continuing to rapidly grow from in 2025.  
          <br></br>
          <br></br>
          Below are some of the most popular brand partnership formats this year
          <br></br>
          <br></br>
          1. Paid partnership
          <br></br>
          2. Affiliate Marketing
          <br></br>
          3. Gifting Partnerships
          <br></br>
          4. Brand Ambassadors
          <br></br>
          5. User Generated Content Partnerships
          <br></br>
          6. Creator Events 
          <br></br>
          7. Creator Content Challenges 
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 5 : 5,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          Influencer Marketing for Brands and Businesses
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 50,
            height: isMobile ? 250 : "",

            width: isMobile ? "80%" : "80%",
          }}
        >
          Influencer marketing is one of the most effective ways to quickly reach your target audience and build relevance and trust with your target audience. 
          People come to social media for inspiration, and the most recent data shows that approximately 60% of people have purchased a product after seing an influencer use it. Influencers can seamlessly integrate product messaging and recommendations into their videos to drive real customer engagement.
          As a result, influencer marketing has become a major engine of growth driving ~6X return on investment. With the ability to directly purchase products from major social channels, the path to purchase is continuing to evolve with the rise of social media.
         <br></br>
          <br></br>
          As marketers continue to focus on campaign KPIs and results, influencer marketing is one of the best
          strategies to boost reach, engagement, and sales for small to large brands. As a result, 1 out of 4 Brands are spending 40% of their marketing budget towards Influencer Marketing initiatives.
          Not only are brands investing more into creator-led strategies, they are also focusing on being more social-first in their overall brand marketing. In this regard, UGC content can dramatically boost a brands capability to create a steady pipeline of social-first content.
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          Influencers Marketing and Social Media for NGOs & Not-for-Profits
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 50,
            height: isMobile ? 180 : "",

            width: isMobile ? "80%" : "80%",
          }}
        >

          Since 2020, social media has become one of the most powerful community and fundraising tools that inspires and engages audiences. Recent results show that over 55% of people who learn about an organization on social media end up taking action in some way. This is a big number for not-for-profits, as they continue grow their marketing efforts to reach their fundraising goals. 
          For not-for-profits, creating strong camapigns to incentivize fundraising is not easy. This is why influencer marketing is such a powerful marketing strategy. When you partner with influencers you 1. Reach broad audiences with authentic messaging from real people, 2. Instill trust in audiences, 3. Gain relevance in a crowded market. 
          
          <br></br>
          <br></br>
          In fact, approximately 1 in 3 donors are most inspired to give via social media, followed closely by email. This is a dramatic shift in the channels of growth for NGOs and not-for-profits. 
          Social media channels prove effective in creating movements and inspiring fundraising efforts, with key case studies like the BLM and Me Too movements, fundraising efforts for national disastors like the US wildfires, and global crises. According to popular online fundraising platform Classy, social media drives 57% of traffic to online fundraising campaign pages.

          <br></br>
          <br></br>
          Finding the right influencer for your brand can be tough, but you can
          do it within minutes on Gigl. Find, partner, and collaborate with
          hundreds of creators for your next influencer marketing campaign.
 
        </p>

        
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          Influencers Marketing for Creators
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 50,
            height: isMobile ? 180 : "",

            width: isMobile ? "80%" : "80%",
          }}
        >

          The creator economy has rapidly risen in the last two years with more content creators than ever before. As brands invest more into influencer marketing, being a creator can become more lucrative this year by diversifying your monetization channels.
          Nano to celebrity level influencers can start monetizing their content through UGC and affiliate partnerships, as well as paid brand partnerships. You can find your next opportunity on Gigl! 
          <br></br>
          <br></br>
          With uncertainties around TikTok early this year, being diversified in your social channels is of utmost importance to protect your following and community. An easy way to do this is by downloading your TikTok content and reposting across your other owned channels. 
          However, with increased number of creators, its difficult to stand out. This year it is important to create engaging content within your niche that helps you stand out among other creators and build a loyal followership.

        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          How do you launch an influencer marketing campaign?
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            height: isMobile ? 340 : "",
          }}
        >
          Launch your next influencer marketing campaign on Gigl in minutes! Discover the right creaotr for your campaign and streamline your campaigns targetting, partnerships, and reporting all in one place
        </p>


        <Button
          style={{
            display: "block",
            backgroundColor: "black",
            fontSize: isMobile ? 9 : 15,
            marginTop: 50,
            marginLeft: isMobile ? 30 : 0,
          }}
          onClick={() => navigate("/register")}
        >
          Get Started on Gigl Today
        </Button>
      </div>

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "black",
          minHeight: 20,
        }}
      ></Grid>

    

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "#4158D0",
          backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
          minHeight: 20,
        }}
      ></Grid>

      <Grid
        container
        spacing={1}
        sx={{
          width: isMobile ? "100vw" : "80vw",
          margin: "0 auto",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        <Grid item xs={isMobile ? 12 : 4}>
          <div
            style={{ display: isMobile ? "" : "flex", alignItems: "center" }}
          >
            <Typography variant="h3"> GIGL</Typography>
          </div>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/privacy")}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/terms")}
          >
            Terms and Conditions
          </Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/limited-use")}
          >
            Limited Use
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          {isMobile ? (
            <p style={{ fontSize: 9 }}>Toronto, Ontario, Canada</p>
          ) : (
            <>
              {" "}
              <p style={{ fontSize: 9 }}>Toronto</p>
              <p style={{ fontSize: 9 }}>Ontario, Canada</p>
              <p style={{ fontSize: 9 }}>giglgroup.com</p>
            </>
          )}
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <p style={{ fontSize: 9, marginRight: 15, marginLeft: 15 }}>
            Contact Us: support@giglgroup.com
          </p>
          <p style={{ fontSize: 8, marginRight: 15, marginLeft: 15 }}>
            Copyright @ Gigl 2023.
          </p>
        </Grid>
      </Grid>
    </>
  );
}
